.topNav {
  padding: 0px 200px;

  .brandLogo {
    width: 350px;
  }
}

* {
  font-size: small
}

.HeaderNavBtn {
  &:hover {
    background-color: #0C2D48 !important;
  }
}

// .hideScroll {
//   /* IE and Edge */
//   -ms-overflow-style: none;
//   /* Firefox */
//   scrollbar-width: none;

//   &::-webkit-scrollbar {
//     /* Hide scrollbar for Chrome, Safari and Opera */
//     display: none;
//   }
// }

.navMaxWidth {
  width: max-content !important;
}

.marginErr {
  padding: 3rem 3rem 8rem 3rem;
}

.navbar-toggler {
  border: none !important;

  &:focus {
    box-shadow: none !important;
  }
}

.snsHome {
  background-image: url("./img/bg2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.logo {
  width: 100px;
  float: right;
  padding: 20px;
}

.contentContainer {
  padding: 0px 200px;
}

.Carousel {
  padding-top: 10px;

  .CarouselItem {
    img {
      height: 50vh;
    }
  }
}

.snsLogo {
  width: 70vw;
}

.navbar-brand img {
  width: 10vw;
}

.carosalBg {
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  opacity: 0.8;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.headerContainer {
  text-align: center;

  .mainHeader {
    color: rgb(227, 30, 36);
  }
}

.carouselImg {
  width: 100%;
  height: 100%;
}

.infoCardContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;

  .infoCards {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 25px;
    text-align: justify;
    color: rgb(36, 29, 29);
    background: rgba(255, 255, 255, 0.719);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
}

.visionAndMission {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;

  .visionAndMissionBody {
    display: flex;
    text-align: justify;
    gap: 20px;

    div {
      padding: 20px;
      background: rgba(255, 255, 255, 0.719);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
}

.img {
  border-radius: 10px;
}

.custBg {
  padding: 10px;
  background: rgba(255, 255, 255, 0.623);
  border-radius: 16px;
}

.custBg1 {
  color: black;
  padding: 20px;
  background: rgba(255, 255, 255, 0.719);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.plantAndInfra {
  display: grid;

  .plantAndInfraContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    gap: 20px;
  }
}

.aboutUs {
  color: white;
  text-align: justify;
}

.customer {
  .customerImg {
    display: grid;
    grid-template-columns: repeat(5, auto);
    align-items: center;
    justify-content: center;
  }
}

.footer {
  z-index: -10;
  display: flex;
  align-items: center;
  background-color: transparent;
}

.pos1 {
  grid-area: pos1;
}

.pos2 {
  grid-area: pos2;
}

.searchBox {
  width: 50vw;
}

.filtercust {
  width: 25%;
}

.mainHeight {
  // height: 110vh;
  padding: 0.25rem 0.25rem 6rem 0.25rem !important;
}

//Quality check
.QtyBody {
  background: #87CEEB;
  height: 475px;

  .qtyTitle {
    padding-top: .5rem;
  }

  .QtyCol1 {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 1rem;
    gap: 5px;

    .QtyTestDesc {
      width: 50vw;
    }

    .QtyCount {
      width: 8vw;
    }
  }

  .QtyCol2Body {
    height: 300px;
    overflow-x: hidden;
    overflow-y: auto;

    .QtyCol2 {
      gap: 5px;
      padding: .5em 1rem .5em 1rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 90vw;
      margin: auto;


      .QtyTestDesc2 {
        width: 20vw;
      }

      .innerQtyCol2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 5px;
      }
    }
  }
}

.chartWidth {
  width: 80vw;
}

.woInProgressInputs {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.ytdmtdInputs {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  &>* {
    flex: 1 0 21%;
  }
}

@media screen and (max-width: 991px) {

  .searchBox {
    width: 50%;
  }

  .filtercust {
    width: 50%;
  }

  .apps {

    .appContainer,
    .appContainer1 {
      flex: 1 0 33% !important;
    }
  }

  .QtyBody {
    height: 75vh;

    .QtyCol1 {
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;


      .QtyTestDesc {
        width: 80vw;
      }

      .QtyCount {
        width: 25vw;
      }
    }

    .QtyCol2Body {
      height: 32vh;

      .QtyCol2 {
        display: flex;
        flex-direction: column;
        width: 100%;

        .QtyTestDesc2 {
          width: 100%;
        }

        .innerQtyCol2 {
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 5px;
        }
      }
    }
  }

  .responsive-nav a {
    width: 80vw;
    align-self: center;
  }

  .topNav,
  .contentContainer {
    padding: 0px 100px;
  }

  body {
    height: 1080px;
    background-position: 50% 0;
  }

  .infoCardContainer,
  .visionAndMission .visionAndMissionBody {
    flex-direction: column;
    grid-template-columns: auto;
  }

  .plantAndInfra {
    .plantAndInfraContent {
      grid-template-columns: repeat(1, auto);
    }
  }
}

@media screen and (max-width: 650px) {

  .masterbody {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .responsive-nav a {
    width: 80vw;
    align-self: center;
  }

  .navbar-brand img {
    width: 30vw !important;
  }

  .stage-head {
    gap: 1rem !important;
  }

  .topNav,
  .contentContainer {
    padding: 0px;
  }

  .footer {
    font-size: 1rem;
  }

  .customer {
    .customerImg {
      grid-template-columns: repeat(1, auto);
    }
  }

  .topNav {
    .brandLogo {
      width: 300px;
    }
  }

  .snsLogo {
    width: 90vw;
  }

  .chartWidth {
    width: 90vw;
  }

  .woInProgressInputs {
    flex-wrap: wrap;

    &>* {
      flex: 1 0 40%;
    }
  }

  .ytdmtdInputs {
    flex-wrap: wrap;

    &>* {
      flex: 1 0 40%;
    }
  }

  .ant-picker-panel-container .ant-picker-panels {
    flex-direction: column;
  }
}

.topNav {
  padding: 0px 200px;

  .brandLogo {
    width: 350px;
  }
}



.webHome {
  // background-image: url("./img/mainBg.jpg");
  // background-image: url("./img/bg1.jpg");
  background-image: url("./img/bg2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.logo {
  width: 100px;
  float: right;
  padding: 20px;
}

.contentContainer {
  padding: 0px 200px;
}

.Carousel {
  padding-top: 10px;

  .CarouselItem {
    img {
      height: 50vh;
    }
  }
}

.snsLogo {
  width: 70vw;
  // height:max-content;
}

.carosalBg {
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  opacity: 0.8;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.headerContainer {
  // height: max-content;
  // padding: 20px;
  text-align: center;
  // background-color: rgba(rgb(12, 11, 11), .1);
  // border-radius: 25px;
  // box-shadow: 1px 1px 50px rgba(250, 247, 247,.1);

  // background: rgba(255, 255, 255, 0.2);
  // border-radius: 16px;
  // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  // backdrop-filter: blur(2px);
  // -webkit-backdrop-filter: blur(5px);
  // border: 1px solid rgba(255, 255, 255, 0.3);

  .mainHeader {
    color: rgb(227, 30, 36);
    // text-shadow: 3px 5px 5px rgb(44, 42, 42);
  }
}

.subHeader {}

.carouselImg {
  width: 100%;
  height: 100%;
  // image-resol;
  // background-image: url('./img/DSC03768.JPG');
  // background-repeat: no-repeat;
  // background-size:cover;

  // backdrop-filter: blur(10px);
  // opacity: .9;
}

.infoCardContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;

  .infoCards {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 25px;
    text-align: justify;

    color: rgb(36, 29, 29);
    //
    // border: 1px inset black;
    // border-radius: 1px;
    // box-shadow: 10px 5px 10px 1px rgb(29, 27, 27);

    background: rgba(255, 255, 255, 0.719);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    // background: rgba(255, 255, 255, 0.5);
    // border-radius: 16px;
    // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    // backdrop-filter: blur(1px);
    // -webkit-backdrop-filter: blur(5px);
    // border: 1px solid rgba(255, 255, 255, 0.3);
  }
}

.visionAndMission {
  text-align: center;
  // color: rgb(248, 245, 245);

  // background: rgba(61, 56, 56, 0.5);
  // border-radius: 16px;
  // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  // backdrop-filter: blur(1px);
  // -webkit-backdrop-filter: blur(5px);
  // border: 1px solid rgba(255, 255, 255, 0.3);

  // background: rgba(214, 211, 211, 0.726);

  // background: rgba(255, 255, 255, 0.719);
  // border-radius: 16px;
  // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.466);
  // backdrop-filter: blur(1px);
  // -webkit-backdrop-filter: blur(100px);
  // border: 1px solid rgba(255, 255, 255, 0.3);

  padding-top: 30px;
  padding-bottom: 30px;

  .visionAndMissionBody {
    display: flex;
    text-align: justify;
    gap: 20px;

    div {
      padding: 20px;

      // background: rgba(255, 255, 255, 0.5);
      // border-radius: 16px;
      // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      // backdrop-filter: blur(1px);
      // -webkit-backdrop-filter: blur(15px);
      // border: 1px solid rgba(255, 255, 255, 0.5);

      background: rgba(255, 255, 255, 0.719);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
}

.img {
  border-radius: 10px;
  // box-shadow: 1px 1px 10px rgb(250, 247, 247);
  // box-shadow: 10px 10px 15px black;
}

.custBg {
  padding: 10px;
  background: rgba(255, 255, 255, 0.623);
  border-radius: 16px;
  // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  // backdrop-filter: blur(10px);
  // -webkit-backdrop-filter: blur(5px);
  // border: 1px solid rgba(255, 255, 255, 0.3);
}

.custBg1 {
  color: black;
  padding: 20px;
  background: rgba(255, 255, 255, 0.719);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.plantAndInfra {
  display: grid;
  // gap: 20px;
  // background-image: url("./img/SNS\ PHOTOS_filters/DSC03847.JPG");

  // background-size: cover;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // padding-top: 20px;
  // padding-bottom: 20px;

  // border-radius: 16px;
  // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  // backdrop-filter: blur(1px);
  // border: 1px solid rgba(255, 255, 255, 0.3);

  .plantAndInfraContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    gap: 20px;
  }
}

.aboutUs {
  color: white;
  text-align: justify;
}

.customer {
  .customerImg {
    display: grid;
    grid-template-columns: repeat(5, auto);
    // display: flex;
    // flex-wrap: wrap;
    // gap: 20px;
    align-items: center;
    justify-content: center;
    // div {
    //   img {
    //     width: 200px;
    //     height: 150px;
    //   }
    // }
  }
}

.footer {
  z-index: -10;
  // padding: 10px;
  display: flex;
  align-items: center;
  // color: white;
}

.pos1 {
  grid-area: pos1;
}

.pos2 {
  grid-area: pos2;
}

@media screen and (max-width: 991px) {

  .navbar-brand img {
    width: 20vw;
  }

  .topNav,
  .contentContainer {
    padding: 0px 100px;
  }

  body {
    // background-size: cover;
    background-position: 50% 0;
    // background-size:100vh!important;
  }



  .infoCardContainer,
  .visionAndMission .visionAndMissionBody {
    flex-direction: column;
    grid-template-columns: auto;
  }

  .plantAndInfra {
    .plantAndInfraContent {
      // grid-template-areas: ' pos';
      grid-template-columns: repeat(1, auto);
    }
  }
}

@media screen and (max-width: 650px) {
  .stage-head {
    gap: 1rem !important;
  }

  .searchBoxContainer {
    flex-direction: column;
  }

  .topNav,
  .contentContainer {
    padding: 0px;
  }

  .footer {
    font-size: 1rem;
  }

  .customer {
    .customerImg {
      grid-template-columns: repeat(1, auto);
    }
  }

  .topNav {
    .brandLogo {
      width: 300px;
    }
  }

  .snsLogo {
    width: 90vw;
    // height:max-content;
  }

  .footer {
    display: none;
  }
}

.contentContainer {
  padding: 20px 150px;
  // display: flex;
  // flex-direction: column;
  // height: 100%;
}

.content {
  align-items: center;
}

.contentHeader {
  padding: 10px;
}

.contentBody {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
}

.contentFooter {
  padding: 10px;
  text-align: center;
}

// common serch form style
.searchForm {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  .searchInput {
    width: 200px;
  }

  .searchFormBtns {
    display: flex;
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.stage-head {
  gap: 3rem;
}

// table header fix scss
.tableFixHead {
  overflow: auto;
  max-height: calc(100vh - 205px);

  thead th {
    padding: 20px;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: rgba(31, 29, 29, 0.8);
    backdrop-filter: blur(20px);
    border-radius: 2px;
    color: white;
  }

  .Shortage-body {
    background-color: orange;
  }

  .Rejection-body {
    background-color: red;
  }

  tbody td {
    padding-left: 20px;

  }
}

// //Navbar for Master
// .masternav {
//   a {
//     text-decoration: none;
//     color: #FFFFFF8C;
//   &:hover{
//     color: #FFFFFF8C;
//   }
//   }
// }

.apps {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;

  .appContainer,
  .appContainer1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 25%;
    // width: max-content;
    width: 200px;
    height: 150px;
    // padding: 20px;
    // height: max-content;
    // border-radius: 50%;
    background-color: #357EC7;
    box-shadow: 5px 5px 5px rgb(65, 64, 64);
    border-radius: 5px;
    cursor: pointer;
    // box-shadow: 10px 8px 15px 20px gray;
    transition: transform .2s;

    /* Animation */
    h3 {
      text-shadow: 5px 4px 3px rgb(65, 64, 64);
      color: white;
      font-weight: 600;
    }
  }

  .appContainer1:hover {
    background-color: #3071b3;
  }

  .appContainer:hover {
    background-color: #3071b3;
    transform: scale(1.06);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

.previousbtn {
  margin: 10px;
}

.masterbox {
  min-height: 60vh;
  max-height: 60vh;
  overflow: auto;
}



.masterbody {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 10px;
}

// d-flex justify-content-center align-items-center gap-2 pb-3
.subBomGrp {
  padding: 0 10px 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.qtyRep {
  grid-template-columns: repeat(5, 1fr);

}

.proRep {
  grid-template-columns: repeat(4, 1fr);

}

.masterbtn {
  // margin: 10px;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  div {
    display: flex;
    gap: 1rem;
  }
}

//testMaster
.Test_body {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 10px;

  .Test_body_col1 {
    display: grid;
    grid-template-columns: 1fr 1fr 3fr;
    // grid-template-columns: 25% auto;
    gap: 5px;
  }

  .Test_body_col2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0 5px 0;
  }

  .Test_body_col3 {
    display: grid;
    grid-template-columns: 25% auto;
    gap: 5px;
    padding: 20px 0 0 0
  }

  .Test_body_col4 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
  }
}

// ppo search and upload content container
.searchAndUploadPPo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
}

.searchBoxContainer {
  display: flex;
}

// add wo btn css
.btn-circle.btn-sm {
  width: 30px;
  height: 30px;
  padding: 0px 0px;
  border-radius: 15px;
  font-size: small;
  text-align: center;
  line-height: normal;
  transition: transform .2s;

  &:hover {
    transform: scale(1.2);
  }
}

.rdt_TableHeadRow {
  font-size: 1rem;
  background-color: black !important;
}

.rdt_TableCol {
  // background-color: black;
  color: white;
}

.rdt_TableCell {
  font-size: 1rem;
}

.woDashboard {
  .contentHeader {
    display: flex;
    justify-content: space-between;
  }
}

.woOptions {
  height: 100%;
  overflow: auto;
  display: grid;
  gap: 30px;
  background-color: white;

  .woOptionSection {
    overflow: auto;
  }
}

@media screen and (max-width: 650px) {

  #stage-headh3 {
    font-size: 1rem;
  }

  .shortrejrow {
    display: flex;
    flex-direction: column;
  }

  .stage-head {
    gap: 1rem !important;
    font-size: 1rem;
  }

  .contentContainer {
    padding: 10px;
  }

  .apps {

    .appContainer,
    .appContainer1 {
      flex: 1 0 33%;
    }
  }

  .woDashboard {
    .contentHeader {
      display: flex;
      flex-direction: column;

      .addBtn {
        float: right;
      }
    }

    .contentBody {
      min-height: 200px;
      max-height: calc(100vh - 250px);
      overflow-y: scroll;
    }
  }

  .searchAndUploadPPo {
    flex-direction: column;
    align-items: unset;
    gap: 10px;
  }
}
// .file-drop-area {
//   position: relative;
//   display: flex;
//   align-items: center;
//   width: 400px;
//   max-width: 100%;
//   padding: 25px;
//   border: 1px dashed black;
//   border-radius: 3px;
//   transition: 0.2s;
 
// }

// .choose-file-button {
//   flex-shrink: 0;
//   background-color: gray;
//   border: 1px solid black;
//   border-radius: 3px;
//   padding: 8px 15px;
//   margin-right: 10px;
//   font-size: 12px;
//   text-transform: uppercase;
// }

// .file-message {
//   font-size: small;
//   font-weight: 300;
//   line-height: 1.4;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

// .file-input {
//   position: absolute;
//   left: 0;
//   top: 0;
//   height: 100%;
//   width: 100%;
//   cursor: pointer;
//   opacity: 0;
  
// }

.modal-backdrop{
  z-index: 5!important;
}

body{
  background-color:#f2f7fb;
}

.mt-100{
  margin-top:100px;
  width: 40vw;
}
.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 0 5px 0 rgba(43,43,43,.1), 0 11px 6px -7px rgba(43,43,43,.1);
  box-shadow: 0 0 5px 0 rgba(43,43,43,.1), 0 11px 6px -7px rgba(43,43,43,.1);
  border: none;
  margin-bottom: 5px;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.card .card-header {
  background-color: transparent;
  border-bottom: none;
  padding: 20px;
  position: relative;
}

.card .card-header h5:after {
  content: "";
  background-color: #d2d2d2;
  width: 101px;
  height: 1px;
  position: absolute;
  bottom: 6px;
  left: 20px;
}

.card .card-block {
  padding: 1.25rem;
  display: flex;
  justify-content: center;
}

.dropzone.dz-clickable {
  cursor: pointer;
}

.dropzone {
  min-height: 10px;
  border: 1px solid rgba(42,42,42,0.05);
  background: rgba(204,204,204,0.15);
  padding: 20px;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px 0 rgba(43,43,43,0.1);
  box-shadow: inset 0 0 5px 0 rgba(43,43,43,0.1);
}

.m-t-20 {
  margin-top: 20px;
}

.btn-primary, .sweet-alert button.confirm, .wizard>.actions a {
  background-color: #4099ff;
  border-color: #4099ff;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all ease-in .3s;
  transition: all ease-in .3s;
}

.btn {
  border-radius: 2px;
  text-transform: capitalize;
  font-size: 15px;
  padding: 10px 19px;
  cursor: pointer;
}
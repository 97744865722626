.main_body {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .table_body {
        width: 75vw;
        height: max-content;
    }

    .stage_body {
        width: 24vw;
    }
}

@media screen and (max-width: 900px) {
    .main_body {
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem;
        gap: 2rem;

        .table_body {
            width: 100% !important;
        }

        .stage_body {
            width: 100% !important;
        }
    }

}
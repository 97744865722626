.ant-tabs-tab-btn {
    width: 100%;
}

.repoContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 1rem;
}

.repoFolders {
    align-items: center;
    background: #f2f2f2 !important;
    width: max-content;
    min-width: 100% !important;
    padding: 10px;

    &:focus,
    &:hover {
        color: black !important;
        background: #8AC7DB !important;
    }
}

.repoFiles {
    align-items: center;
    background: #90EE90 !important;
    width: max-content;
    min-width: 100% !important;
    padding: 10px;

    &:focus,
    &:hover {
        color: black !important;
        border-color: forestgreen !important;
        background: #65e765 !important;
    }
}

@media screen and (max-width: 650px) {
    .repoContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}